import { createApp } from 'vue';
import pinia from '@/store/store';
import * as Sentry from '@sentry/vue';
import { BrowserTracing } from '@sentry/tracing';
// import ls from '@livesession/sdk';

import App from './App.vue';
import router from './router';
import Cleave from 'cleave.js';

import 'iframe-resizer/js/iframeResizer.contentWindow';

// Fontawesome
import { library } from '@fortawesome/fontawesome-svg-core';
import { faCaretRight, faChevronUp, faChevronDown, faArrowLeftLong, faXmark, faCheck, faTrash } from '@fortawesome/pro-solid-svg-icons';
import { faCalendar, faCircleInfo } from '@fortawesome/pro-regular-svg-icons';
import { faPen } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
library.add(faCaretRight, faCalendar, faChevronUp, faChevronDown, faArrowLeftLong, faPen, faXmark, faCheck, faCircleInfo, faTrash);

import 'flag-icons/css/flag-icons.min.css';
import 'bulma/bulma.sass';
import 'balloon-css/balloon.min.css';
import '@/assets/app.sass';
import '@/assets/mobile.sass';

const app = createApp(App);
import { i18n } from './i18n';

if (import.meta.env.VITE_APP_ENV && import.meta.env.VITE_APP_ENV !== 'development') {
  Sentry.init({
    app,
    dsn: import.meta.env.VITE_SENTRY_DSN,
    integrations: [
      new BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
        tracePropagationTargets: ['localhost', 'takberakning.benders-k8s.duvadev.se', /^\//],
      }),
    ],
    tracesSampleRate: 0.1,
  });
}

// const lsOptions = {} as {
//   rootHostname?: string;
// };
// const sdkOptions = {
//   devMode: process.env.NODE_ENV === 'development',
// } as {
//   devMode: boolean;
//   scriptURL: string;
// };
// if (process.env.NODE_ENV === 'production') {
//   lsOptions.rootHostname = '.benders.se';
// } else if (process.env.NODE_ENV === 'staging') {
//   lsOptions.rootHostname = '.benders-k8s.duvadev.se';
// }
// ls.init('3fcf25c4.139d7b04', lsOptions, sdkOptions);
// ls.newPageView();

app.use(pinia);
app.use(router);
app.use(i18n);
app.component('font-awesome-icon', FontAwesomeIcon);

app.directive('cleave', {
  mounted: (el, binding) => {
    el.cleave = new Cleave(el, binding.value || {});
  },
  updated: (el) => {
    const event = new Event('input', { bubbles: true });
    setTimeout(function () {
      el.value = el.cleave.properties.result;
      el.dispatchEvent(event);
    }, 100);
  },
});

app.mount('#app');
